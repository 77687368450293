
import _ from 'lodash';
import { Prop, Vue, Component } from 'vue-property-decorator';
import { baseURL, LiiteDtoTyyppiEnum, LiitetiedostotParam, MaarayksetParams, MaaraysLiiteDtoTyyppiEnum, PerusteKaikkiDtoTyyppiEnum } from '@shared/api/eperusteet';
import { isKoulutustyyppiAmmatillinen, isKoulutustyyppiPdfTuettuOpintopolku } from '@shared/utils/perusteet';
import { Kielet, UiKielet } from '@shared/stores/kieli';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpField from '@shared/components/forms/EpField.vue';
import EpDatepicker from '@shared/components/forms/EpDatepicker.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';
import EpJulkaisuHistoriaJulkinen from '@shared/components/EpJulkaisuHistoriaJulkinen/EpJulkaisuHistoriaJulkinen.vue';

@Component({
  components: {
    EpJulkaisuHistoriaJulkinen,
    EpFormContent,
    EpField,
    EpDatepicker,
    EpSpinner,
    EpContentViewer,
    EpExternalLink,
  },
})
export default class RoutePerusteTiedot extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  private isLoading = true;
  private maarayskirjeObj;

  async mounted() {
    this.handleMaarayskirje();
    this.isLoading = false;
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get isAmmatillinen() {
    return this.peruste && isKoulutustyyppiAmmatillinen(this.peruste.koulutustyyppi!);
  }

  get isOpas() {
    return this.peruste?.tyyppi === _.toLower(PerusteKaikkiDtoTyyppiEnum.OPAS);
  }

  get korvaavatPerusteet() {
    const perusteetByDiaarinumero = _.groupBy(this.perusteDataStore.korvaavatPerusteet, 'diaarinumero');
    return _.map(this.peruste?.korvattavatDiaarinumerot, diaarinumero => ({
      diaarinumero,
      perusteet: perusteetByDiaarinumero[diaarinumero] || [],
    }));
  }

  get peruste() {
    return this.perusteDataStore.peruste;
  }

  get kvliitteita() {
    return _.some(UiKielet, uiKieli => {
      return this.perusteDataStore.kvLiitteet[uiKieli];
    });
  }

  get kvliitteet() {
    return this.perusteDataStore.kvLiitteet;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get termit() {
    return this.perusteDataStore.termit;
  }

  get julkaisut() {
    return this.perusteDataStore.julkaisut;
  }

  get liitteet() {
    return _.map(this.perusteDataStore.liitteet, kvo => (
      {
        ...kvo,
        url: baseURL + LiitetiedostotParam.getLiite(this.peruste!.id!, kvo.id!).url,
      }
    ));
  }

  get koulutusvienninOhjeet() {
    return _.filter(this.liitteet, liite => liite.tyyppi === _.toLower(LiiteDtoTyyppiEnum.KOULUTUSVIENNINOHJE));
  }

  get kaannokset() {
    return _.filter(this.liitteet, liite => liite.tyyppi === _.toLower(LiiteDtoTyyppiEnum.KAANNOS));
  }

  get osaamisalaKuvaukset() {
    return _.chain((this.peruste?.suoritustavat as any[]))
      .map(suoritustapa => this.perusteDataStore.osaamisalaKuvaukset[suoritustapa.suoritustapakoodi!])
      .map(suoritustavanOsaamisalakuvaukset => _.values(suoritustavanOsaamisalakuvaukset))
      .flatMap()
      .flatMap()
      .value();
  }

  handleMaarayskirje() {
    this.maarayskirjeObj = this.handleMaarays(this.peruste!.maarayskirje);
  }

  handleMaarays(maaraysObj) {
    const result = {};
    if (maaraysObj) {
      // Käytetään ensisijaisesti liitteitä
      if (!_.isEmpty(maaraysObj.liitteet)) {
        _.each(maaraysObj.liitteet, (liite, kieli) => {
          result[kieli] = {
            ...liite,
            url: baseURL + LiitetiedostotParam.getLiite(this.peruste!.id!, liite.id!).url,
          };
        });
      }
      else if (!_.isEmpty(maaraysObj.url)) {
        _.each(maaraysObj.url, (url, kieli) => {
          result[kieli] = {
            nimi: url,
            url,
          };
        });
      }
    }
    return result;
  }

  get maarayskirje() {
    return this.maarayskirjeObj[Kielet.sisaltoKieli.value];
  }

  get hasMaarayskirje() {
    return this.peruste?.maarayskirje && this.maarayskirjeObj[Kielet.sisaltoKieli.value];
  }

  get hasMuutosmaaraykset() {
    return !_.isEmpty(this.muutosmaaraykset);
  }

  get muutosmaaraykset() {
    return [
      ...this.perusteenMuutosmaaraykset,
      ...this.maarayskokoelmanMuutosmaaraykset,
    ];
  }

  get perusteenMuutosmaaraykset() {
    return _.chain(this.peruste?.muutosmaaraykset)
      .filter(muutosmaarays => _.has(muutosmaarays.liitteet, this.kieli))
      .map(muutosmaarays => {
        return {
          ...muutosmaarays,
          url: baseURL + LiitetiedostotParam.getLiite(this.peruste!.id!, muutosmaarays.liitteet![this.kieli].id!).url,
          nimi: !!muutosmaarays.nimi && muutosmaarays.nimi[this.kieli] ? muutosmaarays.nimi[this.kieli] : muutosmaarays.liitteet![this.kieli].nimi,
        };
      })
      .value();
  }

  get maarayskokoelmanMuutosmaaraykset() {
    return _.chain(this.perusteDataStore.muutosmaaraykset)
      .map(muutosmaarays => {
        const muutosmaaraysLiite = _.find(muutosmaarays!.liitteet![this.kieli].liitteet, { tyyppi: MaaraysLiiteDtoTyyppiEnum.MAARAYSDOKUMENTTI });
        const liitteet = _.filter(muutosmaarays!.liitteet![this.kieli].liitteet, { tyyppi: MaaraysLiiteDtoTyyppiEnum.LIITE });
        return {
          ...muutosmaarays,
          ...(!!muutosmaaraysLiite && { url: baseURL + MaarayksetParams.getMaaraysLiite(_.toString(muutosmaaraysLiite!.id)).url }),
          liitteet: _.sortBy(_.map(liitteet, liite => {
            return {
              ...liite,
              url: baseURL + MaarayksetParams.getMaaraysLiite(_.toString(liite.id)).url,
            };
          }), liite => this.$kaanna(liite.nimi)),
        };
      })
      .filter('url')
      .sortBy('voimassaoloAlkaa')
      .reverse()
      .value();
  }

  get hasKorvattavatDiaarinumerot() {
    return !_.isEmpty(this.korvaavatPerusteet);
  }

  get korvattavatDiaarinumerotFields() {
    return [{
      key: 'diaarinumero',
      label: this.$t('diaarinumero'),
    }, {
      key: 'perusteet',
      label: this.$t('perusteet'),
    }];
  }

  get dokumentti() {
    if (this.isOpas || isKoulutustyyppiPdfTuettuOpintopolku(this.peruste?.koulutustyyppi)) {
      return this.perusteDataStore.dokumentti;
    }
  }

  get dokumenttiKielistykset() {
    return {
      'opas': {
        'otsikko': 'opas-pdfna',
        'linkki': 'avaa-opas-pdfna',
      },
      'normaali': {
        'otsikko': 'peruste-pdfna',
        'linkki': 'avaa-peruste-pdfna',
      },
    }[this.peruste?.tyyppi || 'normaali'];
  }

  get koulutuskooditFields() {
    return [{
      key: 'koulutuskoodiArvo',
      label: this.$t('koodi'),
      thStyle: 'width: 15%',
    }, {
      key: 'nimi',
      label: this.$t('koulutuksen-nimi'),
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value);
      },
    }];
  }

  get koulutusvienninohjeFields() {
    return [{
      key: 'nimi',
      label: this.$t('tiedosto'),
    }, {
      key: 'lisatieto',
      label: this.$t('diaarinumero'),
      thStyle: 'width: 30%',
    }];
  }

  get osaamisalatFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi'),
      thStyle: 'width: 75%',
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value);
      },
    }, {
      key: 'arvo',
      label: this.$t('koodi'),
      thStyle: 'width: 15%',
    }];
  }

  get tutkintonimikkeetFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi'),
      thStyle: 'width: 75%',
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value);
      },
    }, {
      key: 'tutkintonimikeArvo',
      label: this.$t('koodi'),
      thStyle: 'width: 15%',
    }];
  }

  get muutosmaarayksetFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi'),
      thStyle: { width: '75%' },
      thClass: 'border-bottom-1',
      sortable: false,
    }, {
      key: 'voimassaoloAlkaa',
      label: this.$t('voimassaolo-alkaa'),
      thClass: 'border-bottom-1',
      sortable: false,
      formatter: (value: any, key: any, item: any) => {
        return (this as any).$sd(value);
      },
    }];
  }

  get isEiTarvitaOhjettaTyyppi() {
    return this.peruste?.poikkeamismaaraysTyyppi?.valueOf() === 'ei_tarvita_ohjetta';
  }

  get isEiVoiPoiketaTyyppi() {
    return this.peruste?.poikkeamismaaraysTyyppi?.valueOf() === 'ei_voi_poiketa';
  }

  get isKoulutusvientiliiteTyyppi() {
    return this.peruste?.poikkeamismaaraysTyyppi?.valueOf() === 'koulutusvientiliite';
  }

  get showKoulutusvienninOhje() {
    return this.isEiTarvitaOhjettaTyyppi || this.isEiVoiPoiketaTyyppi || (this.isKoulutusvientiliiteTyyppi && this.koulutusvienninOhjeet && this.koulutusvienninOhjeet.length > 0);
  }
}
