
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpJulkaisuLista from '@shared//components/EpJulkaisuHistoriaJulkinen/EpJulkaisuLista.vue';

interface Julkaisu {
  muutosmaaraysVoimaan?: Date;
  revision?: number;
  julkinenTiedote?: { [key: string]: string; };
  luotu?: Date;
  tila?: 'JULKAISTU' | 'KESKEN' | 'VIRHE';
  liitteet?: any;
}

@Component({
  components: {
    EpJulkaisuLista,
    EpButton,
    EpSpinner,
    EpCollapse,
  },
})
export default class EpJulkaisuHistoriaJulkinen extends Vue {
  @Prop({ required: false })
  private julkaisut!: Julkaisu[];

  get julkaisutMapped() {
    return _.chain(this.julkaisut)
      .map(julkaisu => {
        return {
          ...julkaisu,
          tila: julkaisu.tila || 'JULKAISTU',
          liitteet: this.muutosmaaraysLiite(julkaisu),
        };
      })
      .sortBy('revision')
      .reverse()
      .value();
  }

  get latestJulkaisuRevision() {
    return _.find(this.julkaisutMapped, julkaisu => julkaisu.tila === 'JULKAISTU');
  }

  muutosmaaraysLiite(julkaisu) {
    if (julkaisu.liitteet && julkaisu.liitteet.length > 0) {
      julkaisu.liitteet.forEach(liiteData => {
        liiteData.url = `/eperusteet-service/api/perusteet/${julkaisu.peruste.id!}/julkaisu/liitteet/${liiteData.liite.id}`;
      });
      return julkaisu.liitteet;
    }
    else {
      return [];
    }
  }
}
