
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { KoulutuksenJarjestajaStore } from '@/stores/KoulutuksenJarjestajaStore';
import { Meta } from '@shared/utils/decorators';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpAmmatillinenRow from '@/components/EpAmmatillinen/EpAmmatillinenRow.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import { Kielet } from '@shared/stores/kieli';
import * as _ from 'lodash';
import OpetussuunnitelmaTile from '@/routes/kooste/OpetussuunnitelmaTile.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import { murupolkuKoulutuksenJarjestaja } from '@/utils/murupolku';

@Component({
  components: {
    EpHeader,
    EpSpinner,
    EpButton,
    EpAmmatillinenRow,
    EpSearch,
    OpetussuunnitelmaTile,
    EpBPagination,
  },
})
export default class RouteKoulutuksenJarjestaja extends Vue {
  @Prop({ required: true })
  private koulutuksenJarjestajaStore!: KoulutuksenJarjestajaStore;

  private naytaOtsikkoKaikki = false;
  private query = '';
  private page = 1;

  private opsQuery = '';
  private opsPage = 1;
  private fetching = false;

  private perPage = 5;

  get koulutustyyppi() {
    return 'ammatillinen';
  }

  get koulutustoimija() {
    return this.koulutuksenJarjestajaStore.koulutustoimija.value;
  }

  get yhteisetOsuudet() {
    if (this.koulutuksenJarjestajaStore.yhteisetOsuudet.value) {
      return _.map(this.koulutuksenJarjestajaStore.yhteisetOsuudet.value, yhteinenOsuus => {
        return {
          ...yhteinenOsuus,
          route: { name: 'toteutussuunnitelma',
            params: {
              toteutussuunnitelmaId: _.toString(yhteinenOsuus.id),
              koulutustyyppi: this.koulutustyyppi,
            },
          },
        };
      });
    }
  }

  get toteutussuunnitelmat() {
    if (this.koulutuksenJarjestajaStore.toteutussuunnitelmat.value) {
      return _.map(this.koulutuksenJarjestajaStore.toteutussuunnitelmat.value.data, toteutussuunnitelma => {
        return {
          ...toteutussuunnitelma,
          route: {
            name: 'toteutussuunnitelma',
            params: { toteutussuunnitelmaId: _.toString(toteutussuunnitelma.id),
              koulutustyyppi: this.koulutustyyppi,
            },
          },
        };
      });
    }
  }

  get toteutussuunnitelmaTotal() {
    return this.koulutuksenJarjestajaStore.toteutussuunnitelmat.value?.kokonaismäärä;
  }

  get yhteisetOsuudetFiltered() {
    return _.chain(this.yhteisetOsuudet)
      .filter(ops => Kielet.search(this.query, ops.nimi))
      .value();
  }

  get yhteisetOsuudetPaginated() {
    return _.chain(this.yhteisetOsuudetFiltered)
      .drop(this.perPage * (this.page - 1))
      .take(this.perPage)
      .value();
  }

  @Watch('opsQuery')
  async opsQueryChange() {
    this.opsPage = 1;
    await this.doFetch();
  }

  @Watch('opsPage')
  async pageChange() {
    await this.doFetch();
  }

  async doFetch() {
    this.fetching = true;
    await this.koulutuksenJarjestajaStore.fetchToteutussuunnitelmat(this.opsQuery, this.opsPage - 1);
    this.fetching = false;
  }

  get murupolku() {
    return murupolkuKoulutuksenJarjestaja(this.koulutustyyppi, this.koulutustoimija);
  }

  @Meta
  getMetaInfo() {
    if (this.koulutustoimija) {
      return {
        title: (this as any).$kaanna(this.koulutustoimija.nimi),
      };
    }
  }
}
