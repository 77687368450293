
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpButton from '../EpButton/EpButton.vue';
import EpInput from '../forms/EpInput.vue';
import EpErrorWrapper from '../forms/EpErrorWrapper.vue';
import EpExternalLink from '../EpExternalLink/EpExternalLink.vue';
import EpSpinner from '../EpSpinner/EpSpinner.vue';
import EpKoodistoSelect from '../EpKoodistoSelect/EpKoodistoSelect.vue';
import { KoodistoSelectStore } from '@shared/components/EpKoodistoSelect/KoodistoSelectStore';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import { metadataToLocalized } from '../../utils/perusteet';
import { delay } from '../../utils/delay';
import _ from 'lodash';
import Kayttolistaus from './Kayttolistaus.vue';

@Component({
  components: {
    EpButton,
    EpErrorWrapper,
    EpExternalLink,
    EpInput,
    EpKoodistoSelect,
    EpSpinner,
    Kayttolistaus,
    EpMaterialIcon,
  },
})
export default class VaatimusField extends Vue {
  @Prop({ required: true })
  private value!: any;

  @Prop({ required: true })
  private koodisto!: KoodistoSelectStore;

  @Prop({ default: null })
  private validation!: any;

  private focused = false;
  private hasChanged = false;
  private isLoading = false;

  get vaatimus() {
    return this.value?.vaatimus ? this.value.vaatimus[this.$slang.value] : '';
  }

  get isDatalistVisible() {
    return this.focused
      && this.hasChanged
      && (this.isLoading || this.koodit.length > 0);
  }

  get koodit() {
    const res = _.map(this.koodisto?.data.value?.data || [], koodi => {
      const localized = metadataToLocalized(koodi.metadata!, 'nimi');
      const nimi = localized[this.$slang.value] || '';
      const idx = nimi.indexOf(this.vaatimus);
      return {
        ...koodi,
        nimi: localized,
        completion: {
          left: nimi.substring(0, idx),
          hit: this.vaatimus,
          right: nimi.substring(idx + this.vaatimus.length),
        },
        uri: koodi.koodiUri,
      };
    });
    return res;
  }

  async fetchKoodisto(query: string) {
    this.hasChanged = true;
    try {
      this.isLoading = true;
      await this.koodisto.query(query || '');
    }
    catch (err) {
      console.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  async onInput(ev: string) {
    this.$emit('input', {
      ...this.value,
      vaatimus: {
        ...this.value.vaatimus,
        [this.$slang.value]: ev,
      },
    });
    await this.fetchKoodisto(ev);
  }

  mounted() {
  }

  onResize() {
  }

  onBlur() {
    setTimeout(() => {
      this.focused = false;
    }, 300);
  }

  async valitse(koodi) {
    await delay(100);
    this.focused = false;
    this.$emit('input', {
      koodi: {
        uri: koodi.koodiUri,
        arvo: koodi.koodiArvo,
        nimi: koodi.nimi,
        versio: koodi.versio,
        koodisto: koodi.koodisto.koodistoUri,
      },
    });
  }

  private readonly datalist = _.uniqueId('datalist_');

  get koodiArvo() {
    return _.size(this.value.koodi.uri?.split('_')) === 2 ? this.value.koodi.uri?.split('_')[1] : this.value.koodi.arvo;
  }
}
